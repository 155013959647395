<template>
  <div class="white pa-5 pt-9 pr-0">
    <v-row class="w-100">
      <v-col cols="12" md="12" class="">
        <v-form v-model="valid" @submit.prevent="submitHandler" ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <div class="white-bg">
                <div class="b-bottom">
                  <span class="font-500 font-18 black--text mb-1 d-block"
                    >Send a notification</span
                  >
                  <p class="font-14 black-grey--text">
                    Use the form below to share your questions or concerns..
                  </p>
                </div>
                <div class="b-bottom pb-4">
                  <v-radio-group
                    v-model="obj.type"
                    :disabled="
                      this.user !== undefined || this.activity !== undefined
                    "
                    column
                  >
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-for="(type, i) in groups"
                        :key="i"
                      >
                        <div class="d-flex align-start">
                          <v-radio class="mt-1" :value="type.value">
                            <template v-slot:label>
                              <div>
                                <span class="d-block black--text font-15">{{
                                  type.title
                                }}</span>
                                <span
                                  class="d-block black-grey--text font-13"
                                  >{{ type.desc }}</span
                                >
                              </div>
                            </template>
                          </v-radio>
                        </div>
                      </v-col>
                    </v-row>
                  </v-radio-group>

                  <div :key="obj.type" v-if="obj.type == 'user'">
                    <v-autocomplete
                      :disabled="user !== undefined"
                      v-model="selectedUsers"
                      :items="displayedUsers"
                      outlined
                      dense
                      chips
                      :filter="() => true"
                      itemValue=""
                      small-chips
                      label="Search for user"
                      multiple
                      menu-props="auto"
                      :search-input.sync="userSearchQuery"
                      @update:search-input="searchUsers"
                      @input="userSearchQuery = undefined"
                    >
                      <template v-slot:item="{ item }">
                        <div class="d-flex mt-3">
                          <div
                            class="mr-2 d-block rounded-circle"
                            style="width: 24px; height: 24px; overflow: hidden"
                          >
                            <img
                              class=""
                              v-if="item.google && item.google.picture"
                              :src="item.google.picture"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </div>
                          <p class="d-block">
                            {{ item.name }}
                          </p>
                        </div>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          :key="index"
                          close
                          @click:close="selectedUsers.splice(index, 1)"
                          class="ma-2"
                          :disabled="user !== undefined"
                        >
                          <div class="d-flex mt-3">
                            <div
                              class="mr-2 d-block rounded-circle"
                              style="
                                width: 24px;
                                height: 24px;
                                overflow: hidden;
                              "
                              v-if="item.google && item.google.picture"
                            >
                              <img
                                class=""
                                :src="item.google.picture"
                                alt=""
                                width="24"
                                height="24"
                              />
                            </div>
                            <p class="d-block">
                              {{ item.name }}
                            </p>
                          </div>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div :key="obj.type" v-if="obj.type == 'activity'">
                    <v-autocomplete
                      :disabled="activity !== undefined"
                      v-model="selectedActivity"
                      :items="displayedExperiences"
                      outlined
                      dense
                      small-chip
                      :filter="() => true"
                      itemValue=""
                      label="Search for experience"
                      menu-props="auto"
                      @update:search-input="searchExperiences"
                      :search-input.sync="experienceSearchQuery"
                      @input="experienceSearchQuery = undefined"
                    >
                      <template v-slot:item="{ item }">
                        <div class="d-flex mt-3">
                          <div
                            class="mr-2 d-block rounded-circle"
                            style="width: 24px; height: 24px; overflow: hidden"
                          >
                            <img
                              class=""
                              v-if="item.coverImage && item.coverImage.url"
                              :src="item.coverImage && item.coverImage.url"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </div>
                          <p class="d-block">
                            {{ item.title }}
                          </p>
                        </div>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip
                          class="ma-2"
                          close
                          @click:close="selectedActivity = undefined"
                          :disabled="activity !== undefined"
                        >
                          <div class="d-flex mt-3">
                            <div
                              class="mr-2 d-block rounded-circle"
                              style="
                                width: 24px;
                                height: 24px;
                                overflow: hidden;
                              "
                              v-if="item.coverImage && item.coverImage.url"
                            >
                              <img
                                class=""
                                :src="item.coverImage && item.coverImage.url"
                                alt=""
                                width="24"
                                height="24"
                              />
                            </div>
                            <p class="d-block">
                              {{ item.title }}
                            </p>
                          </div>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
                <!-- form -->
                <v-row class="mt-3">
                  <!-- title -->
                  <v-col cols="12">
                    <span class="font-14 black-grey--text b-block">Title</span>
                    <base-input
                      placeholder="title"
                      v-model="obj.title"
                    ></base-input>
                  </v-col>
                  <!-- body -->
                  <v-col cols="12">
                    <span class="font-14 black-grey--text b-block">Body</span>
                    <base-input
                      placeholder="Body"
                      v-model="obj.body"
                    ></base-input>
                  </v-col>
                  <!-- description -->
                  <v-col cols="12" md="6">
                    <span class="font-14 black-grey--text b-block"
                      >Route To</span
                    >
                    <base-select
                      :items="routes"
                      placeholder="Route"
                      v-model="obj.route"
                      itemValue="value"
                      :returnObject="false"
                    ></base-select>
                  </v-col>
                  <!-- description -->
                  <!-- <v-col cols="12" md="6">
                    <span class="font-14 black-grey--text b-block"
                      >Selected Link</span
                    >
                    <base-input
                      placeholder="title"
                      :value="obj.routeId"
                      :readOnly="true"
                    ></base-input>
                  </v-col> -->
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-col cols="12" class="mt-5">
            <!-- buttons -->
            <buttons-experience
              @cancel="close"
              @next="submitHandler"
              text="Send"
              :isValid="valid"
              :key="valid"
              :hideCancelButton="hideCancelButton"
            ></buttons-experience>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
const StatType = {
  init: function (key, value) {
    return { key: key, value: value };
  }
};

const RouteEnum = {
  home: StatType.init("Home", "home"),
  directory: StatType.init("Directory", "directory"),
  tripPlanner: StatType.init("Trip Planner", "trip-planner"),
  allValues: function () {
    return [
      {
        title: RouteEnum.home.key,
        value: RouteEnum.home.value
      },
      {
        title: RouteEnum.directory.key,
        value: RouteEnum.directory.value
      },
      {
        title: RouteEnum.tripPlanner.key,
        value: RouteEnum.tripPlanner.value
      }
    ];
  },
  init: function (value) {
    let mapped = this.allValues.map((item) => {
      return item.value == value;
    });
    if (mapped && mapped.length > 0) {
      return mapped[0];
    } else {
      return undefined;
    }
  }
};

export default {
  props: {
    user: {
      type: Object,
      default: undefined
    },
    activity: {
      type: Object,
      default: undefined
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonsExperience
  },
  data: () => ({
    userSearchQuery: undefined,
    experienceSearchQuery: undefined,
    valid: false,
    users: [],
    experiences: [],
    groups: [
      { title: "General", value: "general", desc: "Sent for everyone" },
      {
        title: "Per Experience",
        value: "activity",
        desc: "Users who booked a specific experience"
      },
      { title: "Per User", value: "user", desc: "Sent for specific users" }
    ],
    obj: undefined,
    selectedUsers: [],
    selectedActivity: undefined
  }),
  computed: {
    routes() {
      return RouteEnum.allValues();
    },
    displayedUsers() {
      return [...this.users, ...this.selectedUsers];
    },
    displayedExperiences() {
      return [...this.experiences, this.selectedActivity];
    }
  },
  methods: {
    reset() {
      this.obj = {
        type: undefined,
        title: undefined,
        body: undefined,
        route: undefined,
        routeId: undefined,
        activityId: undefined,
        users: undefined
      };
    },
    close() {
      this.$emit("close");
    },
    async submitHandler() {
      this.obj.users = this.selectedUsers.map((item) => {
        return item._id;
      });
      this.obj.activityId = this.selectedActivity?._id;
      if (this.obj.type == "activity" && !this.obj.activityId) {
        this.$store.dispatch("showSnack", {
          text: "Activity is missing",
          color: "error"
        });
        return;
      }

      if (
        this.obj.type == "user" &&
        (!this.obj.users || this.obj.users.length == 0)
      ) {
        this.$store.dispatch("showSnack", {
          text: "Users are missing",
          color: "error"
        });
        return;
      }
      let res = await this.$axios.post("notification", this.obj);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Sent successfully",
          color: "success"
        });
        this.reset();
        this.$refs.form.resetValidation();
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    async searchExperiences(searchWord) {
      if (this.activity) {
        searchWord = this.activity.title;
      }
      let { data } = await this.$axios.get(
        `activity/all/new?searchWord=${searchWord || ""}&pageSize=20`
      );
      if (data) {
        this.experiences = data.data.map((experience) => {
          return {
            title: experience.title,
            _id: experience._id,
            coverImage: experience.coverImage
          };
        });
      }
    },
    async searchUsers(searchWord) {
      let pageSize = 20;
      if (this.user) {
        searchWord = this.user.name;
        pageSize = "";
      }
      let { data } = await this.$axios.get(
        `users/all/new?searchWord=${searchWord || ""}&pageSize=${pageSize}`
      );
      if (data) {
        this.users = data.data;
      }
    }
  },
  async created() {
    this.reset();
    if (this.user) {
      this.obj.type = "user";
      this.selectedUsers = [this.user];
    } else if (this.activity) {
      this.searchExperiences();
      this.obj.type = "activity";
      this.selectedActivity = this.activity
    } else {
      this.searchExperiences();
      this.searchUsers();
    }
  }
};
</script>
<style lang="scss">
.notifications-page {
  background: #fbfbfd;
  max-height: 100vh;
  overflow-y: auto;
  .v-select__slot {
    label {
      font-size: 14px;
      color: $black-grey;
    }
  }
}
</style>
