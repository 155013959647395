<template>
  <div class="notifications-page py-9 px-7">
    <new-notification hideCancelButton />
  </div>
</template>

<script>
import NewNotification from "@/components/dialogs/NewNotification.vue";
export default {
  components: {
    NewNotification
  }
};
</script>
